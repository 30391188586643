import Navigatie from "../components/navigaties";
import Footers from "../components/footers";
import { Helmet } from "react-helmet";
import { config } from "../config";
import BreadCrumb from "../components/breadcrumb";
import ImageZoom from "../components/imageZoom";
function P_Currvit(props) {
    function renderNav() {
        if (props.location.pathname.includes("student")) {
            return <Navigatie type="studenten" />;
        } else if (props.location.pathname.includes("bedrijf")) {
            return <Navigatie type="bedrijven" />;
        } else {
            return <Navigatie type="home" />;
        }
    }

    function renderBreadCrumb() {
        if (props.location.pathname.includes("student")) {
            return <BreadCrumb prev={[["/student", "Home"]]} now="Currvit" />;
        } else if (props.location.pathname.includes("bedrijf")) {
            return <BreadCrumb prev={[["/bedrijf", "Home"]]} now="Currvit" />;
        } else {
            return <BreadCrumb prev={[["/", "Home"]]} now="Currvit" />;
        }
    }

    return (
        <div className="mr-t-30">
            <Helmet>
                <title>HR - Currvit</title>
            </Helmet>
            {renderNav()}
            <div className="container w-65 xl-w-75 m-w-90">
                {renderBreadCrumb()}
                <div className="lh-13 mr-t-15 mr-b-15">
                    <h1 className="mr-b-5">Inschrijven Job en Stage event</h1>
                    <p>
                        Wil je als <strong className="c-blue-400">student</strong> een kijkje komen nemen op het Job en Stage event 2024? Schrijf je dan hier in via onderstaande link.
                    </p>
                    <br />
                    <p>Nieuw dit jaar is het platform ‘Currvit’. Hiermee kunnen studenten en bedrijven eenvoudig cv’s uitwisselen en makkelijker connecties leggen.</p> <br />{" "}
                    <p>
                        Iedereen die zich inschrijft en registreert kan gratis van dit platform gebruik maken. Als student krijg je een persoonlijke QR-code die bedrijven kunnen scannen en op deze manier jouw cv kunnen ontvangen. Zij kunnen nota’s maken en eventueel later contact zoeken mocht jij
                        een perfecte fit zijn binnen hun bedrijf.
                    </p>{" "}
                    <br />
                    <p>
                        {" "}
                        Inschrijven en registreren is simpel: scan de qr-code of klik op{" "}
                        <a className="link c-blue-400" target="_BLANK" href="https://currvit.com/student/join?a=aOBvIho0hYePhXVgs9rMBL6UMjZ2&e=9sQO4dlWmplgZ0PLdmPc">
                            deze link
                        </a>{" "}
                        en registreer! Je kan ook steeds achteraf je cv uploaden.
                    </p>
                    <br />
                    <img src={config.base + "/images/currvit.png"} alt="Hermes Job & Stage Event inschrijven studenten" className="w-30 s-w-100" />
                </div>
            </div>
            <Footers type="main" />
        </div>
    );
}

export default P_Currvit;
