import Navigatie from "../../../components/navigaties";
import Footers from "../../../components/footers";
import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { config } from "../../../config";

function P_Paneel_JS_Home(props) {
    const [events, setEvents] = useState([]);
    const [event, setEvent] = useState(null);
    const [counters, setCounters] = useState({
        deelnemers: null,
        inschrijvingen: null
    });
    useEffect(() => {
        axios
            .get(config.api + "/jobfair")
            .then((result) => {
                setEvents(result.data);
                let activeYear = 2024;
                result.data.forEach((x) => {
                    if (x.active) {
                        activeYear = x.year;
                    }
                });
                setEvent(activeYear);
            })
            .catch((error) => {});
    }, []);

    useEffect(() => {
        if (event) {
            axios
                .get(config.api + "/jobfair/participations?jobfair=" + event)
                .then((res) => {
                    axios
                        .get(config.api + "/participants?year=" + event)
                        .then((result) => {
                            setCounters({ ...counters, inschrijvingen: res.data.length, deelnemers: result.data.length });
                        })
                        .catch((err) => {});
                })
                .catch((err) => {});
        }
    }, [event]);

    let [claims] = useState(localStorage.getItem("claims") !== null ? JSON.parse(localStorage.getItem("claims")) : null);

    if (claims === null || claims.recruitment_privilege === false) {
        window.location.replace("/");
    } else
        return (
            <div>
                <Helmet>
                    <title>HR - Paneel - Job & Stage Event</title>
                </Helmet>
                <Navigatie type="other" />

                <div className="mr-v-30 container w-65 xl-w-75 m-w-90">
                    <div className="box c-gray-500 pd-10">
                        <h2>Selecteer jaartal</h2>
                        <select
                            value={event}
                            onChange={(e) => {
                                setEvent(e.target.value);
                                setCounters({
                                    deelnemers: null,
                                    inschrijvingen: null
                                });
                            }}
                            className="text-input c-blue-400">
                            {events.map((e) => {
                                return (
                                    <option key={"option-" + e.year} value={e.year}>
                                        {e.year}
                                    </option>
                                );
                            })}
                        </select>
                    </div>

                    <div className="box mr-v-5 pd-10 s-pd-h-4">
                        <h2 className="t-center c-gray-500 t-20">{event}</h2>

                        <div className="row mr-t-3">
                            <div className="col-6 pd-r-8 s-col-12 s-pd-r-0 s-pd-t-5">
                                <a href={config.base + "/paneel/j&s/" + event + "/deelnemers"} className="box border-black-10 hover hover-2 hover-b-gray-100-50 border-r-15 block link pd-3 pd-v-8">
                                    <h3 className="t-center c-black-80">Deelnemers</h3>
                                    <p className="t-center c-blue-400 t-30">{counters.deelnemers !== null ? counters.deelnemers : "..."}</p>
                                </a>
                            </div>

                            <div className="col-6 pd-l-8 s-col-12 s-pd-l-0 s-pd-t-5">
                                <a href={config.base + "/paneel/j&s/" + event + "/inschrijvingen"} className="box border-black-10 hover hover-2 hover-b-gray-100-50 border-r-15 block link pd-3 pd-v-8">
                                    <h3 className="t-center c-black-80">Inschrijvingen</h3>
                                    <p className="t-center c-blue-400 t-30">{counters.inschrijvingen !== null ? counters.inschrijvingen : "..."}</p>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>

                <Footers type="main" />
            </div>
        );
}

export default P_Paneel_JS_Home;
