import React from "react";
import { useForm } from "react-hook-form";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { Helmet } from "react-helmet";
import { store } from "react-notifications-component";
import { config } from "../../config";
import { useState } from "react";
import axios from "axios";
function P_JS_Inschrijven() {
    const [inschrijvenOpen, setInschrijvenOpen] = useState(true);
    const [stage, setStage] = useState(0);

    function showError(msg) {
        store.addNotification({
            title: "Oops! er ging iets mis!",
            message: msg,
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animate__animated", "animate__fadeIn"],
            animationOut: ["animate__animated", "animate__fadeOut"],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }

    const { register, handleSubmit } = useForm();

    function onSubmit(values) {
        if (values.contact_telephonenumber === "" && values.contact_cellphonenumber === "") {
            showError("GSM- of telefoonnummer van contactpersoon moet ingevuld worden!");
            return;
        }
        values["jobfair"] = "b1f352cc-1815-445d-9b7d-374acb838f87";
        if (!!values["contact_website"]) {
            let web = values["contact_website"];
            if (web.includes("http://") || web.includes("https://")) {
            } else {
                web = "http://" + web;
            }
            values["contact_website"] = web;
        }
        axios
            .post(config.api + "/jobfair/participations", values)
            .then(function (response) {
                window.location.replace(config.base + "/j&s/inschrijven/voltooid");
            })
            .catch(function (error) {
                store.addNotification({
                    title: "Inschrijven mislukt!",
                    message: "Inschrijven is mislukt, controleer je gegevens na of probeer een andere keer opnieuw!",
                    type: "danger",
                    insert: "top",
                    container: "top-center",
                    animationIn: ["animate__animated", "animate__fadeIn"],
                    animationOut: ["animate__animated", "animate__fadeOut"],
                    dismiss: {
                        duration: 2000,
                        onScreen: true
                    }
                });
            });
    }

    // TODO:: USE EFFECT DIE DE JOBBEURS DIE ACTIEF STAAT OPHAALT & CONTROLEERT ALS DE INSCHRIJVINGEN OPENSTAAN

    return (
        <div className="gradient-1">
            <Helmet>
                <title>HR - J&S - Inschrijven</title>
            </Helmet>

            <div className="row">
                <div className="box container w-75 m-w-95 mr-v-20 pd-v-10 m-pd-v-5 pd-h-30 xl-pd-h-10 m-pd-h-5 border-r-20">
                    {inschrijvenOpen ? (
                        <>
                            <div className="container w-100 mr-t-4">
                                <a href="/" className="c-gray-300 button b-none link t-12 hover hover-2 hover-c-black">
                                    <AiOutlineArrowLeft />
                                </a>
                            </div>
                            <div className="t-center mr-b-10 mr-t-5">
                                <a href="/">
                                    <img src={config.base + "/images/hermes_rec.png"} alt="Hermes Recruitment" className="hr-login-logo" />
                                </a>
                                <br />
                                <h1 className="pd-t-5 fw-900 c-black-60 t-12">INSCHRIJVEN VOOR JOB & STAGE EVENT</h1>
                            </div>

                            <form onSubmit={handleSubmit(onSubmit)}>
                                {stage === 0 && (
                                    <div>
                                        <h2 className="c-gray-700-60 mr-b-5">Facturatie gegevens</h2>
                                        <label>
                                            Juridische bedrijfsnaam <span className="c-red">*</span>
                                        </label>
                                        <input {...register("contact_company_name")} name="contact_company_name" type="text" className="text-input pd-2 t-8" placeholder="Juridische bedrijfsnaam" required />

                                        <label>Website</label>
                                        <input {...register("contact_website")} name="contact_website" type="text" className="text-input pd-2 t-8" placeholder="https://www.google.com" />

                                        <label>
                                            Facturatie email <span className="c-red">*</span>
                                        </label>
                                        <input {...register("contact_billingmail")} name="contact_billingmail" type="email" className="text-input pd-2 t-8" placeholder="Facturatie email" required />

                                        <label>
                                            BTW nummer <span className="c-red">*</span>
                                        </label>
                                        <input {...register("contact_btw")} name="contact_btw" type="text" className="text-input pd-2 t-8" placeholder="BE 0000.000.000" required />

                                        <label>
                                            Adres gegevens <span className="c-red">*</span>
                                        </label>
                                        <div className="row">
                                            <div className="col-9 s-col-12">
                                                <input {...register("contact_street")} name="contact_street" type="text" className="text-input pd-2 t-8 mr-b-0" placeholder="Straat" required />
                                            </div>
                                            <div className="col-3 pd-l-1 s-col-12 s-pd-l-0">
                                                <input {...register("contact_number")} name="contact_number" type="text" className="text-input pd-2 t-8 mr-b-0" placeholder="Huisnummer (Bus)" required />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-7 s-col-12 s-pd-t-2">
                                                <input {...register("contact_city")} name="contact_city" type="text" className="text-input pd-2 t-8 mr-b-0" placeholder="Stad/Gemeente" required />
                                            </div>
                                            <div className="col-2 pd-h-1 s-col-12 s-pd-h-0">
                                                <input {...register("contact_zipcode")} name="contact_zipcode" type="number" className="text-input pd-2 t-8 mr-b-0" placeholder="Postcode" required />
                                            </div>
                                            <div className="col-3 s-col-12 ">
                                                <input {...register("contact_country")} name="contact_country" type="text" className="text-input pd-2 t-8" placeholder="Land" required />
                                            </div>
                                        </div>
                                        <h2 className="c-gray-700-60 mr-v-5">Contactpersoon gegevens</h2>

                                        <label>
                                            Voornaam <span className="c-red">*</span>
                                        </label>
                                        <input {...register("contact_firstname")} name="contact_firstname" type="text" className="text-input pd-2 t-8" placeholder="Voornaam" required />

                                        <label>
                                            Achternaam <span className="c-red">*</span>
                                        </label>
                                        <input {...register("contact_lastname")} name="contact_lastname" type="text" className="text-input pd-2 t-8" placeholder="Achternaam" required />

                                        <label>
                                            Email <span className="c-red">*</span>
                                        </label>
                                        <input {...register("contact_mail")} name="contact_mail" type="email" className="text-input pd-2 t-8" placeholder="Email" required />

                                        <label>
                                            Telefoonnummer <span className="c-red">*</span>
                                        </label>
                                        <input {...register("contact_telephonenumber")} name="contact_telephonenumber" type="tel" className="text-input pd-2 t-8" placeholder="+32 0 000 00 00" />

                                        <label>
                                            Gsmnummer <span className="c-red">*</span>
                                        </label>
                                        <input {...register("contact_cellphonenumber")} name="contact_cellphonenumber" type="tel" className="text-input pd-2 t-8" placeholder="+32 000 00 00 00" />

                                        <label>
                                            Functie binnen bedrijf <span className="c-red">*</span>
                                        </label>
                                        <input {...register("contact_person_function")} name="contact_person_function" type="text" className="text-input pd-2 t-8" placeholder="Functie" required />

                                        <label>Extra informatie:</label>
                                        <textarea {...register("contact_extra")} className="text-input text-area pd-2 t-8" rows="10" placeholder=""></textarea>
                                        <button onClick={() => setStage(1)} className="button button-s b-gray-400 t-7 c-black fw-700 mr-t-10 mr-b-2">
                                            Volgende
                                        </button>
                                    </div>
                                )}
                                {stage === 1 && (
                                    <div>
                                        <h2 className="c-black-60 mr-b-4">Vertegenwoordigers</h2>
                                        <p className="c-black-40 mr-b-4">Vertegenwoordigers zijn optioneel</p>
                                        <h3 className="c-black-40 mr-b-4">Vertegenwoordiger 1</h3>
                                        {/* Eerste */}
                                        <label>Voornaam</label>
                                        <input {...register("contact_representative1_firstname")} name="contact_representative1_firstname" type="text" className="text-input pd-2 t-8" placeholder="Voornaam" />

                                        <label>Achternaam</label>
                                        <input {...register("contact_representative1_lastname")} name="contact_representative1_lastname" type="text" className="text-input pd-2 t-8" placeholder="Achternaam" />

                                        <label>Email</label>
                                        <input {...register("contact_representative1_mail")} name="contact_representative1_mail" type="email" className="text-input pd-2 t-8" placeholder="Email" />

                                        <label>Gsmnummer</label>
                                        <input {...register("contact_representative1_cellphonenumber")} name="contact_representative1_cellphonenumber" type="tel" className="text-input pd-2 t-8" placeholder="+32 000 00 00 00" />

                                        <label>Functie binnen bedrijf</label>
                                        <input {...register("contact_representative1_function")} name="contact_representative1_function" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                                        <h3 className="c-black-40 mr-b-4">Vertegenwoordiger 2</h3>
                                        {/* Tweede */}
                                        <label>Voornaam</label>
                                        <input {...register("contact_representative2_firstname")} name="contact_representative2_firstname" type="text" className="text-input pd-2 t-8" placeholder="Voornaam" />

                                        <label>Achternaam</label>
                                        <input {...register("contact_representative2_lastname")} name="contact_representative2_lastname" type="text" className="text-input pd-2 t-8" placeholder="Achternaam" />

                                        <label>Email</label>
                                        <input {...register("contact_representative2_mail")} name="contact_representative2_mail" type="email" className="text-input pd-2 t-8" placeholder="Email" />

                                        <label>Gsmnummer</label>
                                        <input {...register("contact_representative2_cellphonenumber")} name="contact_representative2_cellphonenumber" type="tel" className="text-input pd-2 t-8" placeholder="+32 000 00 00 00" />

                                        <label>Functie binnen bedrijf</label>
                                        <input {...register("contact_representative2_function")} name="contact_representative2_function" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                                        <h3 className="c-black-40 mr-b-4">Vertegenwoordiger 3</h3>
                                        {/* Derde */}
                                        <label>Voornaam</label>
                                        <input {...register("contact_representative3_firstname")} name="contact_representative3_firstname" type="text" className="text-input pd-2 t-8" placeholder="Voornaam" />

                                        <label>Achternaam</label>
                                        <input {...register("contact_representative3_lastname")} name="contact_representative3_lastname" type="text" className="text-input pd-2 t-8" placeholder="Achternaam" />

                                        <label>Email</label>
                                        <input {...register("contact_representative3_mail")} name="contact_representative3_mail" type="email" className="text-input pd-2 t-8" placeholder="Email" />

                                        <label>Gsmnummer</label>
                                        <input {...register("contact_representative3_cellphonenumber")} name="contact_representative3_cellphonenumber" type="tel" className="text-input pd-2 t-8" placeholder="+32 000 00 00 00" />

                                        <label>Functie binnen bedrijf</label>
                                        <input {...register("contact_representative3_function")} name="contact_representative3_function" type="text" className="text-input pd-2 t-8" placeholder="Functie" />

                                        <button className="button button-s b-gray-400 t-7 c-black fw-700 mr-t-10 mr-r-2" onClick={() => setStage(0)}>
                                            Vorige
                                        </button>
                                        <input type="submit" className="button button-s b-blue-400 t-7 c-white fw-700 mr-t-10 mr-b-2" value="INSCHRIJVEN" />
                                    </div>
                                )}
                            </form>
                        </>
                    ) : (
                        <>
                            <p>Inschrijvingen zijn momenteel (nog) niet geopend.</p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
}

export default P_JS_Inschrijven;
